import React from 'react'
import { Link } from 'react-router-dom'

export default function FlightsPage() {
    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Flights Page
            <Link to="home"> Home</Link>
        </div>
    )
}
